<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio attività</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="medium">Dettaglio attività</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <div>
          <!--CLIENTE-->
          <div>
            <label>Cliente:</label>
            <ion-item>
              <ion-input readonly>{{task.account_name}}</ion-input>
            </ion-item>
            <br>
          </div>
          <!--COMPANY-->
          <div>
            <label>Company di riferimento:</label>
            <ion-item>
              <ion-select :disabled="$store.state.userRole!=7 ? true : false" v-model="task.company">
                <ion-select-option :value="c.name" v-for="c,i in companies" :key="'company-'+i">{{c.name}}</ion-select-option>
              </ion-select>
            </ion-item>
            <br>
          </div>
          <!--RIFERIMENTO OFFERTA-->
          <div>
            <label>Rif. Offerta:</label>
            <ion-item>
              <ion-input :disabled="$store.state.userRole!=7 ? true : false" v-model="task.ref_off"></ion-input>
            </ion-item>
            <br>
          </div>
          <!--INIZIO/FINE-->
          <div>
            <label>Data inizio/fine:</label>
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.begindate"></ion-input>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.enddate"></ion-input>
            </ion-item>
            <br>
          </div>
          <!--TASK-->
          <div>
            <br>
            <label>Tipologia di task:</label>  
            <br>
            <ion-item>
              <ion-select v-model="task.task_project" :disabled="$store.state.userRole!=7 ? true : false">
                <ion-select-option :value="''+t.id" v-for="t,i in taskprojects" :key="'task-'+i">{{t.name}}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <!--DESCRIZIONE ATTIVITA-->
          <div>
            <br>
            <label>Attività svolte:</label>
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.description"></ion-input>
            </ion-item>
          </div>
          <!--SEDE ATTIVITA-->
          <div>
            <br>
            <label>Sede svolgimento attività:</label>
            <br>
              <ion-item>
                <ion-select :disabled="$store.state.userRole!=7 ? true : false" v-model="task.place">
                  <ion-select-option :value="p" v-for="p,i in place" :key="'place-'+i" required>{{p}}</ion-select-option>
                </ion-select>
              </ion-item>
            <br>
          </div>
          <!--TIPOLOGIA COSTO-->
          <div>
            <br>
            <label>Tipologia di costo:</label>  
            <br>
            <ion-item>
              <ion-select v-model="task.cost" :disabled="$store.state.userRole!=7 ? true : false">
                <ion-select-option :value="''+c.id" v-for="c,i in costs" :key="'cost-'+i">{{c.cost}}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <!--NUMERO VIAGGI-->
          <div>
            <br>
            <label>Numero di viaggi:</label>
            <br>
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.travel" @input="updatekmpedaggi"></ion-input>
            </ion-item>
          </div>
          <!--DISTANZA VIAGGIO-->
          <div>
            <br>
            <label>Km percorsi (solo andata):</label>
            <br>
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.km" @input="updatekmpedaggi"></ion-input>
            </ion-item>
          </div>
          <!--PEDAGGIO-->
          <div>
            <label>Costo pedaggio:</label>  
            <br>
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.travel_cost" @input="updatekmpedaggi"></ion-input>
            </ion-item>
          </div>
          <!--PRANZO-->
          <div>
            <br>
            <label>Costo pranzo:</label><br>
            <br>    
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.lunch_cost"></ion-input>
            </ion-item>
          </div>
          <!--ALTRI COSTI-->
          <div>
            <br>
            <label>Altri costi:</label><br>
            <br>      
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.other_cost"></ion-input>
            </ion-item>
          </div>
          <!--RIMBORSO SPESE-->
          <div>
            <br>
            <label>Rimborso spese:</label>
            <br>
            <ion-radio-group v-model="task.rimb_spese">
              <ion-item>
                <label>Si &nbsp;</label>
                <ion-radio value="1"></ion-radio>
                <label> &nbsp;No &nbsp;</label>
                <ion-radio value="2"></ion-radio>
              </ion-item>
            </ion-radio-group>
        </div>
          <!--NOTE-->
          <div>
            <br>
            <label>Note:</label>
            <br>
            <ion-item>
              <ion-input :readonly="$store.state.userRole!=7 ? true : false" v-model="task.notes"></ion-input>
            </ion-item>
            <br>
          </div>
          <div v-if="$store.state.userRole>4">
            <ion-button @click="modificaAttivita()">MODIFICA</ion-button>
          </div> 
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons, 
    IonContent, 
    IonHeader,
    IonItem, 
    IonInput,
    IonMenuButton, 
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,  
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonItem,
      IonInput,
      IonHeader,
      IonMenuButton,
      IonPage,
      IonRadio,
      IonRadioGroup,   
      IonSelect,
      IonSelectOption, 
      IonTitle,
      IonToolbar,
    },
    data: function(){
      return{
        task:{
          company:'',
          consultant:'',
          begindate:'',
          enddate:'',
          account:'',
          task_project:'',
          description:'',
          ref_off:'',
          place:'',
          address:'',
          hours:0,
          cost:'',
          travel:0,
          km:0,
          travel_cost:0,
          lunch_cost:0,
          other_cost:0,
          notes:'',
          rimb_spese:''
        },
        user:{
          role:0,
        },
        companies:[],
        costs:[],
        taskprojects:[],
        place:['sede','laboratorio','fornitore','cliente'],
      }
    },
    methods:{
      getTaskInfo(){
        this.axios.post(this.apiUrl + 'get-task-info', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          this.task=response.data.task;
          this.task.rimb_spese=response.data.task.rimb_spese.toString();
        })
        this.updatekmpedaggi();
      },
      modificaAttivita(){
        this.axios.post(this.apiUrl + 'update-task', {task:this.task, id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
          this.$router.push('/folder/report-attivita');
          return toast.present();
          }
        })
      }, 
      updatekmpedaggi(){
        setTimeout(() => {
          this.task.totalkm=this.task.km*this.task.travel
          this.task.totalpedaggio=this.task.travel_cost*this.task.travel
        },50)
      },
      getTaskProjects(){
        this.axios.post(this.apiUrl + 'get-task-projects-all', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.taskprojects = response.data.taskprojects              
          }
        })
      },
      getCosts(){
        this.axios.post(this.apiUrl + 'get-costs-all', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code == 200){
            this.costs = response.data.costs
          }
        })
      },
      getCompaniesList(){
        this.axios.post(this.apiUrl + 'get-companies-all', {}, this.headers)
        .then(async (response) => {
            if(response.data.status_code==200){
                this.companies=response.data.companies            
            }
        })
      },
    },
    ionViewWillEnter: function(){
      this.getTaskProjects();
      this.getCosts();
      this.getTaskInfo();
      this.getCompaniesList();
    }
  }
</script>

<style scoped>
  #container{
    width:100%;
  }
  ion-item{
    color:blue;
  }
</style>